import React, { useEffect, createRef } from "react"
import Layout from "../components/layout"
import lottie from "lottie-web"
import animation from "../animations/logodrop.json"

export default function Privacy() {
  let animationContainer = createRef()

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: animation,
    })
    return () => anim.destroy() // optional clean up for unmounting
  }, [])

  return (
    <Layout>
      <div className="container mx-auto flex items-center	flex-col px-4 mb-24">
        <div className=" w-full lg:w-7/12">
          <div className="animation-container" ref={animationContainer} />
        </div>
        <h1 className="font-serif text-4xl md:text-5xl lg:text-6xl mb-10">
          TofuTogether - Privacy Policy
        </h1>
      </div>

      <div className="container mx-auto px-4 mb-24">
        <p className="mb-12">
          Last updated <span>5th January 2021</span>
        </p>
        <p className="mb-4">
          <span>TofuTogether </span> (“we” or “us” or “our”) respects the
          privacy of our users (“user” or “you”). This Privacy Policy explains
          how we collect, use, disclose, and safeguard your information when you
          visit our mobile application (the “Application”).<strong> </strong>
          Please read this Privacy Policy carefully. IF YOU DO NOT AGREE WITH
          THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS THE
          APPLICATION.
        </p>
        <p className="mb-4">
          We reserve the right to make changes to this Privacy Policy at any
          time and for any reason. We will alert you about any changes by
          updating the “Last updated” date of this Privacy Policy. You are
          encouraged to periodically review this Privacy Policy to stay informed
          of updates. You will be deemed to have been made aware of, will be
          subject to, and will be deemed to have accepted the changes in any
          revised Privacy Policy by your continued use of the Application after
          the date such revised Privacy Policy is posted.
        </p>
        <p className="mb-4">
          This Privacy Policy does not apply to the third-party online/mobile
          store from which you install the Application or make payments,
          including any in-game virtual items, which may also collect and use
          data about you. We are not responsible for any of the data collected
          by any such third party.
        </p>
        <p className="mb-4">
          <strong>
            <span>COLLECTION OF YOUR INFORMATION</span>
          </strong>
        </p>
        <p className="mb-4">
          We may collect information about you in a variety of ways. The
          information we may collect via the Application depends on the content
          and materials you use, and includes:
        </p>
        <p className="mb-4">
          <strong>Personal Data</strong>
        </p>
        <p className="mb-4">
          Demographic and other personally identifiable information (such as
          your name and email address) that you voluntarily give to us when
          choosing to participate in various activities related to the
          Application, such as chat, posting messages in comment sections or in
          our forums, liking posts, sending feedback, and responding to surveys.
          If you choose to share data about yourself via your profile, online
          chat, or other interactive areas of the Application, please be advised
          that all data you disclose in these areas is public and your data will
          be accessible to anyone who accesses the Application.
        </p>
        <p className="mb-4">
          <strong>Derivative Data</strong>
        </p>
        <p className="mb-4">
          Information our servers automatically collect when you access the
          Application, such as your native actions that are integral to the
          Application, including liking, re-blogging, or replying to a post, as
          well as other interactions with the Application and other users via
          server log files.
        </p>
        <p className="mb-4">
          <strong>Financial Data</strong>
        </p>
        <p className="mb-4">
          Financial information, such as data related to your payment method
          (e.g. valid credit card number, card brand, expiration date) that we
          may collect when you purchase, order, return, exchange, or request
          information about our services from the Application. We store only
          very limited, if any, financial information that we collect.
          Otherwise, all financial information is stored by our payment
          processor, <span>[Payment Processor Name]</span>, and you are
          encouraged to review their privacy policy and contact them directly
          for responses to your questions.
        </p>
        <p className="mb-4">
          <strong>Facebook Permissions</strong>
        </p>
        <p className="mb-4">
          The Application may by default access your
          <a href="https://www.facebook.com/about/privacy/">Facebook</a> basic
          account information, including your name, email, gender, birthday,
          current city, and profile picture URL, as well as other information
          that you choose to make public. We may also request access to other
          permissions related to your account, such as friends, checkins, and
          likes, and you may choose to grant or deny us access to each
          individual permission. For more information regarding Facebook
          permissions, refer to the{" "}
          <a href="https://developers.facebook.com/docs/permissions/reference">
            Facebook Permissions Reference
          </a>
          page.
        </p>
        <p className="mb-4">
          <strong>Data from Social Networks</strong>
        </p>
        <p className="mb-4">
          User information from social networking sites, such as Facebook and
          Instagram, including your name, your social network username,
          location, gender, birth date, email address, profile picture, and
          public data for contacts, if you connect your account to such social
          networks. This information may also include the contact information of
          anyone you invite to use and/or join the Application.
        </p>
        <p className="mb-4">
          <strong>Geo-Location Information</strong>
        </p>
        <p className="mb-4">
          We may request access or permission to and track location-based
          information from your mobile device, either continuously or while you
          are using the Application, to provide location-based services. If you
          wish to change our access or permissions, you may do so in your
          device’s settings. Your distance from other users will be displayed on
          your profile and other content.
        </p>
        <p className="mb-4">
          <strong>Mobile Device Access</strong>
        </p>
        <p className="mb-4">
          We may request access or permission to certain features from your
          mobile device, including your mobile device’s WiFi, Location / GPS,
          Camera and Storage. If you wish to change our access or permissions,
          you may do so in your device’s settings.
        </p>
        <p className="mb-4">
          <strong>Mobile Device Data</strong>
        </p>
        <p className="mb-4">
          Device information such as your mobile device ID number, model, and
          manufacturer, version of your operating system, phone number, country,
          location, and any other data you choose to provide.
        </p>
        <p className="mb-4">
          <strong>Push Notifications</strong>
        </p>
        <p className="mb-4">
          We may request to send you push notifications regarding your account
          or the Application. If you wish to opt-out from receiving these types
          of communications, you may turn them off in your device’s settings.
        </p>
        <p className="mb-4">
          <strong>Third-Party Data</strong>
        </p>
        <p className="mb-4">
          Information from third parties, such as personal information or
          network friends, if you connect your account to the third party and
          grant the Application permission to access this information.
        </p>
        <p className="mb-4">
          <strong>Data From Contests, Giveaways, and Surveys</strong>
        </p>
        <p className="mb-4">
          Personal and other information you may provide when entering contests
          or giveaways and/or responding to surveys.
        </p>
        <p className="mb-4">
          <strong>USE OF YOUR INFORMATION</strong>
        </p>
        <p className="mb-4">
          Having accurate information about you permits us to provide you with a
          smooth, efficient, and customized experience. Specifically, we may use
          information collected about you via the Application to:{" "}
        </p>
        <ol className="list-disc ml-12 mb-4">
          <li>Administer sweepstakes, promotions, and contests.</li>
          <li>Assist law enforcement and respond to subpoena.</li>
          <li>
            Compile anonymous statistical data and analysis for use internally
            or with third parties.
          </li>
          <li>Create and manage your account.</li>
          <li>
            Deliver targeted advertising, coupons, newsletters, and other
            information regarding promotions and the Application to you.
          </li>
          <li>Email you regarding your account or order.</li>
          <li>Enable user-to-user communications.</li>
          <li>Display your distance from other users.</li>
          <li>
            Show content, feed posts, profile information, status updates or any
            other publication you have sent to the app to other users.
          </li>
          <li>
            Display your profile and associated dating information including
            age, gender or sexuality information with to users.
          </li>
          <li>
            Match you with other users of the app, in relation to dating
            functionality based on your preferences.
          </li>
          <li>
            Fulfill and manage purchases, orders, payments, and other
            transactions related to the Application.
          </li>
          <li>
            Generate a personal profile about you to make future visits to the
            Application more personalized.
          </li>
          <li>Increase the efficiency and operation of the Application.</li>
          <li>
            Monitor and analyze usage and trends to improve your experience with
            the Application.
          </li>
          <li>Notify you of updates to the Application.</li>
          <li>
            Offer new products, services, mobile applications, and/or
            recommendations to you.
          </li>
          <li>Perform other business activities as needed.</li>
          <li>
            Prevent fraudulent transactions, monitor against theft, and protect
            against criminal activity.
          </li>
          <li>Process payments and refunds.</li>
          <li>
            Request feedback and contact you about your use of the Application.
          </li>
          <li>Resolve disputes and troubleshoot problems.</li>
          <li>Respond to product and customer service requests.</li>
          <li>Send you a newsletter.</li>
          <li>Solicit support for the Application.</li>
        </ol>
        <p className="mb-4">
          <strong>DISCLOSURE OF YOUR INFORMATION</strong>
        </p>
        <p className="mb-4">
          We may share information we have collected about you in certain
          situations. Your information may be disclosed as follows:
        </p>
        <p className="mb-4">
          <strong>By Law or to Protect Rights</strong>
        </p>
        <p className="mb-4">
          If we believe the release of information about you is necessary to
          respond to legal process, to investigate or remedy potential
          violations of our policies, or to protect the rights, property, and
          safety of others, we may share your information as permitted or
          required by any applicable law, rule, or regulation. This includes
          exchanging information with other entities for fraud protection and
          credit risk reduction.
        </p>
        <p className="mb-4">
          <strong>Third-Party Service Providers</strong>
        </p>
        <p className="mb-4">
          We may share your information with third parties that perform services
          for us or on our behalf, including payment processing, data analysis,
          email delivery, hosting services, customer service, and marketing
          assistance.
        </p>
        <p className="mb-4">
          The app does use third party services that may collect information
          used to identify you. You can view the privacy policies of the third
          party services providers below.
        </p>
        <ul className="mb-4 ml-12 list-disct">
          <li>
            <a href="https://www.google.com/policies/privacy/" target="_blank">
              Google Play Services
            </a>
          </li>
          <li>
            <a
              href="https://firebase.google.com/policies/analytics"
              target="_blank"
            >
              Firebase
            </a>
          </li>
        </ul>
        <p className="mb-4">
          <strong>Interactions with Other Users</strong>
        </p>
        <p className="mb-4">
          If you interact with other users of the Application, those users may
          see your name, profile photo, gender, sexuality,age, profile
          information and descriptions of your activity, distance, including
          sending invitations to other users, chatting with other users, liking
          posts, following blogs.
        </p>
        <p className="mb-4">
          <strong>Online Postings</strong>
        </p>
        <p className="mb-4">
          When you post comments, feed posts, contributions or other content to
          the Applications, your posts may be viewed by all users and may be
          publicly distributed outside the Application in perpetuity
        </p>
        <p className="mb-4">
          <strong>Third-Party Advertisers</strong>
        </p>
        <p className="mb-4">
          We may use third-party advertising companies to serve ads when you
          visit the Application. These companies may use information about your
          visits to the Application and other websites that are contained in web
          cookies in order to provide advertisements about goods and services of
          interest to you.
        </p>
        <p className="mb-4">
          <strong>Affiliates</strong>
        </p>
        <p className="mb-4">
          We may share your information with our affiliates, in which case we
          will require those affiliates to honor this Privacy Policy. Affiliates
          include our parent company and any subsidiaries, joint venture
          partners or other companies that we control or that are under common
          control with us.
        </p>
        <p className="mb-4">
          <strong>Business Partners</strong>
        </p>
        <p className="mb-4">
          We may share your information with our business partners to offer you
          certain products, services or promotions.
        </p>
        <p className="mb-4">
          <strong>Other Third Parties</strong>
        </p>
        <p className="mb-4">
          We may share your information with advertisers and investors for the
          purpose of conducting general business analysis. We may also share
          your information with such third parties for marketing purposes, as
          permitted by law.
        </p>
        <p className="mb-4">
          <strong>Sale or Bankruptcy</strong>
        </p>
        <p className="mb-4">
          If we reorganize or sell all or a portion of our assets, undergo a
          merger, or are acquired by another entity, we may transfer your
          information to the successor entity. If we go out of business or enter
          bankruptcy, your information would be an asset transferred or acquired
          by a third party. You acknowledge that such transfers may occur and
          that the transferee may decline honor commitments we made in this
          Privacy Policy.
        </p>
        <p className="mb-4">
          We are not responsible for the actions of third parties with whom you
          share personal or sensitive data, and we have no authority to manage
          or control third-party solicitations. If you no longer wish to receive
          correspondence, emails or other communications from third parties, you
          are responsible for contacting the third party directly.
        </p>
        <p className="mb-4">
          <strong>TRACKING TECHNOLOGIES</strong>
        </p>
        <p className="mb-4">
          <strong>Cookies and Web Beacons</strong>
        </p>
        <p className="mb-4">
          We may use cookies, web beacons, tracking pixels, and other tracking
          technologies on the Application to help customize the Application and
          improve your experience. When you access the Application, your
          personal information is not collected through the use of tracking
          technology. Most browsers are set to accept cookies by default. You
          can remove or reject cookies, but be aware that such action could
          affect the availability and functionality of the Application. You may
          not decline web beacons. However, they can be rendered ineffective by
          declining all cookies or by modifying your web browser’s settings to
          notify you each time a cookie is tendered, permitting you to accept or
          decline cookies on an individual basis.
        </p>
        <p className="mb-4">
          <strong>Internet-Based Advertising</strong>
        </p>
        <p className="mb-4">
          Additionally, we may use third-party software to serve ads on the
          Application, implement email marketing campaigns, and manage other
          interactive marketing initiatives. This third-party software may use
          cookies or similar tracking technology to help manage and optimize
          your online experience with us. For more information about opting-out
          of interest-based ads, visit the{" "}
          <a href="http://optout.networkadvertising.org/?c=1">
            Network Advertising Initiative Opt-Out Tool
          </a>{" "}
          or{" "}
          <a
            href="https://optout.aboutads.info/?c=2&amp;lang=EN"
            target="_blank"
            rel="noopener noreferrer"
          >
            Digital Advertising Alliance Opt-Out Tool
          </a>
          .
        </p>
        <p className="mb-4">
          <strong>Application Analytics</strong>
        </p>
        <p className="mb-4">
          We may also partner with selected third-party vendors, such as
          Firebase Analytics to allow tracking technologies and remarketing
          services on the Application, to, among other things, analyze and track
          users’ use of the Application, determine the popularity of certain
          content, and better understand app activity and app crashes. By
          accessing the Application, you consent to the collection and use of
          your information by these third-party vendors. You are encouraged to
          review their privacy policy and contact them directly for responses to
          your questions. We do not transfer personal information to these
          third-party vendors.
        </p>
        <p className="mb-4">
          You should be aware that getting a new computer, installing a new
          browser, upgrading an existing browser, or erasing or otherwise
          altering your browser’s cookies files may also clear certain opt-out
          cookies, plug-ins, or settings.
        </p>
        <p className="mb-4">
          <strong>THIRD-PARTY WEBSITES</strong>
        </p>
        <p className="mb-4">
          The Application may contain links to third-party websites and
          applications of interest, including advertisements and external
          services, that are not affiliated with us. Once you have used these
          links to leave the Application, any information you provide to these
          third parties is not covered by this Privacy Policy, and we cannot
          guarantee the safety and privacy of your information. Before visiting
          and providing any information to any third-party websites, you should
          inform yourself of the privacy policies and practices (if any) of the
          third party responsible for that website, and should take those steps
          necessary to, in your discretion, protect the privacy of your
          information. We are not responsible for the content or privacy and
          security practices and policies of any third parties, including other
          sites, services or applications that may be linked to or from the
          Application.
        </p>
        <p className="mb-4">
          <strong>Legal basis of processing</strong>
        </p>
        We may process Personal Data relating to you, the Users if one of the
        following applies:
        <ul className="mb-4 list-disc ml-12">
          <li>
            Users have given their consent for one or more specific purposes.
            Note: Under some legislations the Owner may be allowed to process
            Personal Data until the User objects to such processing ("opt-out"),
            without having to rely on consent or any other of the following
            legal bases. This, however, does not apply, whenever the processing
            of Personal Data is subject to European data protection law;
          </li>
          <li>
            provision of Data is necessary for the performance of an agreement
            with the User and/or for any pre-contractual obligations thereof;
          </li>
          <li>
            processing is necessary for compliance with a legal obligation to
            which the Owner is subject;
          </li>
          <li>
            processing is related to a task that is carried out in the public
            interest or in the exercise of official authority vested in the
            Owner;
          </li>
          <li>
            processing is necessary for the purposes of the legitimate interests
            pursued by the Owner or by a third party.
          </li>
          <li>
            In any case, the Owner will gladly help to clarify the specific
            legal basis that applies to the processing, and in particular
            whether the provision of Personal Data is a statutory or contractual
            requirement, or a requirement necessary to enter into a contract.
          </li>
        </ul>
        <p className="mb-4">
          <strong>SECURITY OF YOUR INFORMATION</strong>
        </p>
        <p className="mb-4">
          We use administrative, technical, and physical security measures to
          help protect your personal information. While we have taken reasonable
          steps to secure the personal information you provide to us, please be
          aware that despite our efforts, no security measures are perfect or
          impenetrable, and no method of data transmission can be guaranteed
          against any interception or other type of misuse. Any information
          disclosed online is vulnerable to interception and misuse by
          unauthorized parties. Therefore, we cannot guarantee complete security
          if you provide personal information.
        </p>
        <p className="mb-4">
          <strong>POLICY FOR CHILDREN</strong>
        </p>
        <p className="mb-4">
          We do not knowingly solicit information from or market to children
          under the age of 18. If you become aware of any data we have collected
          from children under age 18, please contact us using the contact
          information provided below.
        </p>
        <p className="mb-4">
          <strong>CONTROLS FOR DO-NOT-TRACK FEATURES</strong>
        </p>
        <p className="mb-4">
          Most web browsers and some mobile operating systems include a
          Do-Not-Track (“DNT”) feature or setting you can activate to signal
          your privacy preference not to have data about your online browsing
          activities monitored and collected. No uniform technology standard for
          recognizing and implementing DNT signals has been finalized. As such,
          we do not currently respond to DNT browser signals or any other
          mechanism that automatically communicates your choice not to be
          tracked online. If a standard for online tracking is adopted that we
          must follow in the future, we will inform you about that practice in a
          revised version of this Privacy Policy.
        </p>
        <p className="mb-4">
          <strong>OPTIONS REGARDING YOUR INFORMATION</strong>
        </p>
        <p className="mb-4">
          <strong>Account Information</strong>
        </p>
        <p className="mb-4">
          You may at any time review or change the information in your account
          or terminate your account by:{" "}
        </p>
        <ul className="mb-4">
          <li>
            Logging into your account settings and pressing delete account
          </li>
          <li>Contacting us using the contact information provided below</li>
          <li>
            <span>support@tofutogether.com</span>
          </li>
        </ul>
        <p className="mb-4">
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, some information may be retained in our files to prevent
          fraud, troubleshoot problems, assist with any investigations, enforce
          our Terms of Use and/or comply with legal requirements.
        </p>
        <p className="mb-4">
          <strong>Emails and Communications</strong>
        </p>
        <p className="mb-4">
          If you no longer wish to receive correspondence, emails, or other
          communications from us, you may do this by deleting your account.
        </p>
        <p className="mb-4">
          If you no longer wish to receive correspondence, emails, or other
          communications from third parties, you are responsible for contacting
          the third party directly.
        </p>
        <p className="mb-4">
          <strong>CALIFORNIA PRIVACY RIGHTS</strong>
        </p>
        <p className="mb-4">
          California Civil Code Section 1798.83, also known as the “Shine The
          Light” law, permits our users who are California residents to request
          and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third
          parties for direct marketing purposes and the names and addresses of
          all third parties with which we shared personal information in the
          immediately preceding calendar year. If you are a California resident
          and would like to make such a request, please submit your request in
          writing to us using the contact information provided below.
        </p>
        <p className="mb-4">
          If you are under 18 years of age, reside in California, and have a
          registered account with the Application, you have the right to request
          removal of unwanted data that you publicly post on the Application. To
          request removal of such data, please contact us using the contact
          information provided below, and include the email address associated
          with your account and a statement that you reside in California. We
          will make sure the data is not publicly displayed on the Application,
          but please be aware that the data may not be completely or
          comprehensively removed from our systems.
        </p>
        <p className="mb-4">
          <strong>CONTACT US</strong>
        </p>
        <p className="mb-4">
          If you have questions or comments about this Privacy Policy, please
          contact us at:
        </p>
        <p className="mb-4">
          <span>support@tofutogether.com</span>
        </p>
      </div>
    </Layout>
  )
}
